import React, { createContext, useState } from "react";

export const PhotoNumContext = createContext();

const PhotoNumProvider = ({ children }) => {
  const [photoNum, setPhotoNum] = useState(0);
  function nextPhoto() {
    if (photoNum < 8) {
      setPhotoNum(photoNum + 1);
    } else {
      setPhotoNum(0);
    }
  }
  function prevPhoto() {
    if (photoNum > 0) {
      setPhotoNum(photoNum - 1);
    } else {
      setPhotoNum(8);
    }
  }
  return (
    <PhotoNumContext.Provider
      value={{ photoNum, setPhotoNum, nextPhoto, prevPhoto }}
    >
      {children}
    </PhotoNumContext.Provider>
  );
};

export default PhotoNumProvider;
