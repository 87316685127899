import React, { createContext, useEffect, useState } from "react";
export const PageContext = createContext();
const PageProvider = ({ children }) => {
  const [page, setPage] = useState("main-page");

  useEffect(() => {
    if (page === "gallery-page") {
      setTimeout(() => {
        document.documentElement.classList.add("bg-black");
      }, 4700);
    } else {
      document.documentElement.classList.remove("bg-black");
    }
  }, [page]);

  useEffect(() => {
    const doTransition = () => {
      const mainPage = document.getElementById("main-page");
      // const galleryPage = document.getElementById("gallery-page");
      if (page == "gallery-page") {
        // Fade out main page
        mainPage.style.transition = "opacity 0.85s ease";
        mainPage.style.opacity = "0";

        // Pre-set gallery page to be invisible but displayed
        // galleryPage.style.opacity = "0";
        // galleryPage.style.display = "block";

        // After main page fades out, hide it and start fading in gallery page
        // setTimeout(() => {
        //   mainPage.style.display = "none";

        //   // Use requestAnimationFrame to ensure the display change has been rendered
        //   requestAnimationFrame(() => {
        //     galleryPage.style.transition = "opacity 2.2s ease";
        //     galleryPage.style.opacity = "1";
        //   });
        // }, 2500);
      }
    };

    doTransition();
  }, [page]);

  return (
    <PageContext.Provider value={{ page, setPage }}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
