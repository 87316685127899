import React, { Suspense, useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingScreen from "./pages/LoadingPage";
import { LoadingContext } from "./providers/LoadingProvider";
// import GalleryPage from "./pages/GalleryPage/GalleryPage";

const MainPage = React.lazy(() => import("./pages/MainPage"));
const GalleryPage = React.lazy(() => import("./pages/GalleryPage/GalleryPage"));

const App = () => {
  const { loading, setLoading, setDoFadeout } = useContext(LoadingContext);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 4750));

      setDoFadeout(true);

      setTimeout(() => {
        setLoading(false);
        const loadingPage = document.getElementById("loading-page");
        if (loadingPage) {
          loadingPage.style.display = "none";
        }
      }, 2000);
    };

    loadData();
  }, []);

  return (
    <>
      {loading && <LoadingScreen />}
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
