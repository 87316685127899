import React, { createContext, useState, useContext } from "react";
import { saveAs } from "file-saver";
import { RefContext } from "./RefProvider";

export const CaptchaContext = createContext();

const CaptchaProvider = ({ children }) => {
  const { galleryFolderState } = useContext(RefContext);
  const [captchaOpen, setCaptchaOpen] = useState(false);
  const [captchaUnlocked, setCaptchaUnlocked] = useState(false);
  const [dirToDownload, setDirToDownload] = useState("");

  const handleDownload = async () => {
    try {
      const url = `https://api.ermantania2024.love/download/${galleryFolderState.toLowerCase()}`;

      // Create an invisible anchor element
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `ErmanTania2024-${dirToDownload}.zip`;

      // Append to the document and trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error initiating download:", error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  return (
    <CaptchaContext.Provider
      value={{
        captchaOpen,
        setCaptchaOpen,
        dirToDownload,
        setDirToDownload,
        captchaUnlocked,
        setCaptchaUnlocked,
        handleDownload,
      }}
    >
      {children}
    </CaptchaContext.Provider>
  );
};

export default CaptchaProvider;
