const sectionsArray = [
  // { id: "1", title: "Галлерея фото", ref: 0 },
  { id: "0", title: "О нас", ref: 1 },
  { id: "3", title: "Love Story", ref: 3 },
  { id: "4", title: "Программа дня", ref: 4 },
  // { id: "5", title: "Церемония", ref: 4 },
  { id: "6", title: "Свадебный ужин", ref: 5 },
  { id: "2", title: "F.A.Q.", ref: 2 },
];

const galleryArray = [
  { id: "4", title: "Фотосессия", ref: 4, folder: "Fotosessia", size: "560Мб" },
  { id: "2", title: "Welcome", ref: 2, folder: "Welcome", size: "297Мб" },
  { id: "0", title: "Церемония", ref: 1, folder: "Ceremonia", size: "595Мб" },
  // { id: "6", title: "Сборы", ref: 5, folder: "Sbory" },
  {
    id: "5",
    title: "Фотосессия с гостями",
    ref: 6,
    folder: "Fotosessia-s-gostami",
    size: "597Мб",
  },
  { id: "3", title: "Декор", ref: 3, folder: "Dekor", size: "194Мб" },
  { id: "1", title: "Банкет", ref: 0, folder: "Banket", size: "1.8Гб" },
  {
    id: "7",
    title: "Фото от Александра Петровича",
    ref: 7,
    folder: "AleksandrPetrovich",
    size: "154Мб",
  },
];

const addressSectionArray = [
  {
    id: "5",
    title: "ЦЕРЕМОНИЯ",
    details: "Хорошевский отдел ЗАГС",
    address: "Москва, ул. Народного Ополчения, 23, корп. 2",
    link: "https://yandex.ru/maps/-/CDRw6TKG",
    C: "1",
  },
  {
    id: "5",
    title: "СВАДЕБНЫЙ УЖИН",
    title1: "ПО АДРЕСУ",
    details: "Загородный клуб",
    details1: "ARTILAND",
    address: "г.о. Балашиха, Новское ш., 10, корп. 1",
    link: "https://yandex.ru/maps/-/CDRw6PkL",
    R: "1",
  },
];

const faqArray = [
  {
    question: "Будет ли дресс-код?",
    answer:
      "Мы будем рады, если девушки выберут вечерние или коктейльные платья в пастельных тонах, а мужчины — костюм.",
  },
  {
    question: "Какие подарки предпочтительны?",
    answer:
      "Ваши поздравления в конвертах будут лучшими подарками, которые помогут осуществить нашу мечту о свадебном путешествии! Но также мы будем очень рады Вашим сюрпризам!",
  },
  {
    question: "Кому можно задать вопросы по организации мероприятия?",
    answer:
      "Наш свадебный организатор — Юлия с радостью ответит на ваши вопросы. Ее номер телефона: ",
    a: "+7 (906) 357-86-78.",
  },
  {
    question: "Есть ли у нас свадебный хештег?",
    answer:
      "Да, при публикациях в социальных сетях, используйте этот тег #ermantania2024",
  },
  // {
  //   question: "Нужно ли дарить цветы?",
  //   answer:
  //     "Просим вас не дарить цветы, так как на следующий день мы улетаем в свадебное путешествие и не успеем насладиться красивыми букетами.",
  // },
  {
    question: "Возможно ли приехать на своем автомобиле?",
    answer:
      "Да, на территории отеля имеется парковка. Для бронирования места просим заранее сообщить марку и номер автомобиля.",
  },
];

const programArray = [
  // {
  //   time: "09:00",
  //   title: "Утро невесты",
  //   description: "Фотосессия в отеле Radisson Collection Hotel",
  //   address: "Россия, Москва, Певческий переулок, 4с1",
  //   rose: {
  //     appearance: true,
  //     side: "left",
  //     fill: "",
  //   },
  // },
  // {
  //   time: "09:30",
  //   title: "Сборы жениха",
  //   description: "Фотосессия в отеле Radisson Collection Hotel",
  //   address: "Москва, Большой Трёхсвятительский переулок, 1-3с1",
  //   rose: {
  //     appearance: true,
  //     side: "right",
  //     fill: "",
  //   },
  // },
  // {
  //   time: "11:30",
  //   title: "Свадебная прогулка",
  //   description: "",
  //   address: "",
  //   rose: {
  //     appearance: false,
  //     side: "",
  //     fill: "",
  //   },
  // },
  // {
  //   time: "14:00",
  //   title: "Регистрация в ЗАГСе",
  //   description: "Присутствие по желанию",
  //   address: "Россия, Москва, улица Народного Ополчения, 23к2",
  //   rose: {
  //     appearance: true,
  //     side: "left",
  //     fill: "",
  //   },
  // },
  {
    time: "15:00",
    title: "Сбор гостей, Фуршет",
    description: "",
    // address: "Балашиха, Новское ш., 10, корп. 1",
    rose: {
      appearance: true,
      side: "right",
      fill: "",
    },
  },
  {
    time: "16:00",
    title: "Выездная регистрация",
    description: "",
    // address: "Россия, Москва, Таганская улица, 25",
    rose: {
      appearance: true,
      side: "left",
      fill: "",
    },
  },
  {
    time: "17:00",
    title: "Свадебный ужин",
    description: "",
    // address: "Балашиха, Новское ш., 10, корп. 1",
    rose: {
      appearance: true,
      side: "right",
      fill: "",
    },
  },
  {
    time: "23:00",
    title: "Окончание вечера",
    description: "",
    // address: "Балашиха, Новское ш., 10, корп. 1",
    rose: {
      appearance: true,
      side: "left",
      fill: "",
    },
  },
];

const galleryFolders = [
  "Banket",
  "Ceremonia",
  "Dekor",
  "Fotosessia",
  "Fotosessia-s-gostami",
  "Sbory",
  "Welcome",
];

export default {
  sectionsArray,
  galleryArray,
  addressSectionArray,
  faqArray,
  programArray,
  galleryFolders,
};
