import React, { createContext, useState } from "react";

export const AccessContext = createContext();

const AccessProvider = ({ children }) => {
  const [access, setAccess] = useState(false);

  return (
    <AccessContext.Provider value={{ access, setAccess }}>
      {children}
    </AccessContext.Provider>
  );
};

export default AccessProvider;
