import React, { createContext, useState } from "react";
export const LoadingContext = createContext();
const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [doFadeout, setDoFadeout] = useState(false);

  return (
    <LoadingContext.Provider
      value={{ loading, setLoading, doFadeout, setDoFadeout }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
