import React, { useState, useEffect, useContext } from "react";
import { LoadingContext } from "../providers/LoadingProvider";
const loadingGif = require("../loading.gif");
const LoadingScreen = () => {
  const { doFadeout } = useContext(LoadingContext);

  useEffect(() => {}, []);
  return (
    <div id="loading-page" className={doFadeout === true ? "fade-out" : ""}>
      <img src={loadingGif} id="loading-page-video" />
      <h4 id="loading-page-title">
        Идет загрузка,
        <br />
        Пожалуйста, подождите...
      </h4>
      <div id="loading-page-margin" />
    </div>
  );
};

export default LoadingScreen;
