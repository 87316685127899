import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import LoadingProvider from "./providers/LoadingProvider";
import RefProvider from "./components/Providers/RefProvider";
import AccessProvider from "./components/Providers/AccessProvider";
import PhotoNumProvider from "./components/Providers/PhotoNumProvider";
import PageProvider from "./providers/PageProvider";
import CaptchaProvider from "./components/Providers/CaptchaProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PageProvider>
    <LoadingProvider>
      <RefProvider>
        <AccessProvider>
          <PhotoNumProvider>
            <CaptchaProvider>
              <App />
            </CaptchaProvider>
          </PhotoNumProvider>
        </AccessProvider>
      </RefProvider>
    </LoadingProvider>
  </PageProvider>
);
