import React, { createContext, useState, useEffect, useRef } from "react";
// import { galleryFolders } from "./galleryFolders";
import arraysData from "../../arraysData";
const { galleryArray } = arraysData;
export const RefContext = createContext();

const RefProvider = ({ children }) => {
  const [titleState, setTitleState] = useState("");
  const [folderSizeState, setFolderSizeState] = useState("");
  const [announcementHeight, setAnnouncementHeight] = useState(0);
  const [announcementClosed, setAnnouncementClosed] = useState("");
  const [announcementNone, setAnnouncementNone] = useState("");
  const sectionRefs = useRef([]);
  const headerRef = useRef(null);
  const headerGalleryRef = useRef(null);
  const galleryMainPicRef = useRef(null);
  const [galleryMainPicRefHeight, setGalleryMainPicRefHeight] = useState(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isGalleryHeaderFixed, setIsGalleryHeaderFixed] = useState(false);
  const [galleryFolderState, setGalleryFolderState] = useState("Fotosessia");

  const prevScrollPosRef = useRef(window.pageYOffset);

  useEffect(() => {
    // const setNewTitle = () => {
    const selectedGallery = galleryArray.find(
      (item) => item.folder === galleryFolderState
    );
    if (selectedGallery) {
      setTitleState(selectedGallery.title);
      setFolderSizeState(selectedGallery.size);
    }
    // };

    // const timeoutId = setTimeout(setNewTitle, 200); // 250 milliseconds = 0.25 seconds

    // Cleanup function to clear the timeout if the component unmounts or galleryFolderState changes again
    // return () => clearTimeout(timeoutId);
  }, [galleryFolderState]);

  const setGalleryFolderStateWithTransition = (item) => {
    const currentFolder = document.getElementById("gallery-rest-folder");
    // if (window.pageYOffset < galleryMainPicRefHeight) {
    // currentFolder.classList.add("hidden");
    // window.scrollTo({
    //   top: galleryMainPicRefHeight,
    //   behavior: "smooth",
    // });
    //   setTimeout(() => {
    //     setGalleryFolderState(item.folder);
    //     setTimeout(() => {
    //       currentFolder.classList.remove("hidden");
    //     }, 70);
    //   }, 450);
    // } else {
    currentFolder.classList.add("hidden");
    setTimeout(() => {
      setGalleryFolderState(item.folder);
      if (window.pageYOffset > galleryMainPicRefHeight) {
        window.scrollTo(0, galleryMainPicRefHeight);
      }
      setTimeout(() => {
        currentFolder.classList.remove("hidden");
      }, 70);
    }, 450);
    // }
  };

  const scrollToSection = (index) => {
    const section = sectionRefs.current[index];
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSectionRef = (ref, index) => {
    sectionRefs.current[index] = ref;
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     setScreenWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  // useEffect(() => {
  //   console.log(galleryFolderState);
  // }, [galleryFolderState]);

  useEffect(() => {
    if (galleryMainPicRef.current) {
      const height = galleryMainPicRef.current.offsetHeight;
      setGalleryMainPicRefHeight(height);
    }
  }, [galleryMainPicRef.current]);

  useEffect(() => {
    if (headerGalleryRef.current) {
      // console.log("works");
      const height = headerGalleryRef.current.offsetHeight;
    }
  }, [headerGalleryRef.current]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const header = headerRef.current;
      const headerGallery = headerGalleryRef.current;
      if (header) {
        const headerHeight = header.offsetHeight;

        if (prevScrollPosRef.current > currentScrollPos) {
          // Прокрутка вверх, показать хедер
          setIsHeaderVisible(true);
        } else if (
          prevScrollPosRef.current < currentScrollPos &&
          currentScrollPos > headerHeight + 50
        ) {
          setIsHeaderVisible(false);
        } else {
          setIsHeaderVisible(true);
        }

        prevScrollPosRef.current = currentScrollPos;
      }
      if (headerGallery && galleryMainPicRefHeight) {
        // console.log("headerGallery");

        if (currentScrollPos > galleryMainPicRefHeight - 4) {
          // console.log("more than 700");
          setIsGalleryHeaderFixed(true);
        } else {
          // console.log("less than 700");
          setIsGalleryHeaderFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [galleryMainPicRefHeight]);

  return (
    <RefContext.Provider
      value={{
        sectionRefs,
        headerRef,
        isHeaderVisible,
        setIsHeaderVisible,
        headerGalleryRef,
        galleryMainPicRef,
        galleryMainPicRefHeight,
        setGalleryMainPicRefHeight,
        isGalleryHeaderFixed,
        prevScrollPosRef,
        scrollToSection,
        handleSectionRef,
        galleryFolderState,
        setGalleryFolderState,
        setGalleryFolderStateWithTransition,
        announcementClosed,
        setAnnouncementClosed,
        announcementHeight,
        setAnnouncementHeight,
        announcementNone,
        setAnnouncementNone,
        titleState,
        folderSizeState,
      }}
    >
      {children}
    </RefContext.Provider>
  );
};

export default RefProvider;
